.DetailLayout {
  flex: 4;
}

.DetailLayout__Wrapper {
  padding: 30px 50px;
}

.DetailLayout__Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  background-color: #fff;
  position: sticky;
  top: 0;
}

.DetailLayout__HeaderTitle .Icon {
  font-weight: 400;
  font-size: 24px;
  border: 1px solid #ddd;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  margin-bottom: 10px;
}

.DetailLayout__HeaderTitle .Icon:hover {
  opacity: 0.5;
}

.DetailLayout__Info {
  display: flex;
  align-items: center;
}

.DetailLayout__InfoLeft {
  width: 150px;
  height: 150px;
  margin-right: 20px;
}

.DetailLayout__InfoLeft img {
  width: 100%;
  border-radius: 15px;
}

.DetailLayout__HeaderTitle h2 {
  font-weight: 400;
  font-size: 30px;
}

.DetailLayout__HeaderTitle p {
  font-weight: 400;
  font-size: 18px;
  margin: 10px 0;
}

.DetailLayout__HeaderActions {
  margin: 20px 0;
}

.DetailLayout__Content {
  padding: 20px 0;
  height: 100vh;
}
