.FormLayout {
  flex: 4;
  position: relative;
  background-color: #fff;
}

.FormLayout__Wrapper {
  padding: 30px 50px;
  width: 1080px;
  margin: 0 auto;
}

.FormLayout__Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-left: 0;
  border-right: 0;
  background-color: #fff;

  border-bottom: 1px solid #cdd4c9;

  font-size: 36px;
  line-height: 48px;
  font-weight: 300;
  position: sticky;
  top: 0;
}

.FormLayout__HeaderTitle .Icon {
  font-weight: 400;
  font-size: 24px;
  border: 1px solid #ddd;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 10px 0;
}

.FormLayout__HeaderTitle .Icon:hover {
  opacity: 0.5;
}

.FormLayout__HeaderTitle h2 {
  font-size: 36px;
  line-height: 48px;
  font-weight: 300;
  margin: 0 0;
}

.FormLayout__HeaderTitle p {
  font-weight: 400;
  font-size: 18px;
  margin: 10px 0;
}

.FormLayout__Content {
  padding: 30px 0;
  height: 100vh;
}

/* .FormLayout__Content .footer {
  position: fixed;
  bottom: 0;
  right: 0;
  height: 100px;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 30px 50px;
}

.FormLayout__Content .footer button {
  margin-left: 10px;
} */
